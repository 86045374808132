<template>
  <van-row class="buytrain">
    <!-- <van-nav-bar left-arrow @click-left="$router.go(-1)" /> -->
    <van-image class="pic_margin" width="100vw" :src="''" style="z-index: 1;" />
    <van-row type="flex" justify="center">
      <van-row style=" border-radius: 10px;z-index: 1">
        <van-row style="width: 95vw;border-radius: 10px;background-color: white;">
          <van-row style="padding: 10px">
            <!-- 始末车站选择cell -->
            <van-cell>
              <template slot="title">
                <van-row type="flex" justify="space-between" align="bottom" style="height: 20px;">
                  <van-col>
                    <van-row type="flex" justify="center">
                      <span style="font-size: 10px;color: #adadad">目的地</span>
                    </van-row>
                  </van-col>
                  <van-col>
                    <van-row type="flex" justify="center">
                      <div style="font-size: 10px;color: #adadad"></div>
                    </van-row>
                  </van-col>
                </van-row>
                <van-row type="flex" justify="space-between" align="center" style="height: 40px">
                  <van-col span="24">
                    <van-row
                      type="flex"
                      justify="start"
                      @click="chooseCity('hotel/setOrderFrom')"
                    >
                      <h4
                        class="animate__animated animate__delay-08"
                        ref="left"
                      >{{hotelOrderInfo.destination}}</h4>
                    </van-row>
                  </van-col>
                </van-row>
              </template>
            </van-cell>
            <!-- 始末车站选择cell -->
            <!-- 出发时间选择cell -->
            <van-cell>
              <template slot="title">
                <van-row type="flex" justify="start" align="bottom" style="height: 20px;">
                  <van-col span="12">
                    <van-row type="flex">
                      <span style="font-size: 10px;color: #adadad">入住日期</span>
                    </van-row>
                  </van-col>
                  <van-col span="12">
                    <van-row type="flex">
                      <span style="font-size: 10px;color: #adadad">离店日期</span>
                    </van-row>
                  </van-col>
                </van-row>
                <van-row type="flex" justify="start" align="center" style="height: 40px" @click="show_calendar = true">
                      <van-col span="12">
                        <span class="h4">{{hotelOrderInfo.date[0]|comverTime('MM月DD日')}}<small>{{hotelOrderInfo.date[0]| comverTime('dddd')}}</small></span>
                      </van-col>
                      <van-col span="12">
                        <span class="h4">{{hotelOrderInfo.date[1]|comverTime('MM月DD日')}}<small>{{hotelOrderInfo.date[0]| comverTime('dddd')}}</small></span>
                      </van-col>
                    <!-- </van-row>
                  </van-col> -->
                </van-row>
              </template>
            </van-cell>
            <!-- 出发时间选择cell -->
            <!-- 车型选择cell -->
            <van-cell>
              <template slot="title">
                <van-row type="flex" justify="start" align="bottom" style="height: 20px;">
                  <van-col>
                    <van-row type="flex" justify="center">
                      <span style="font-size: 10px;color: #adadad">我的附近</span>
                    </van-row>
                  </van-col>
                </van-row>
                <van-row type="flex" justify="start" align="center" style="height: 40px">
                  <van-col>
                    <van-row type="flex" justify="start" align="bottom">
                      <van-col>
                        <van-field style="padding-left:0;font-size:1.4rem" v-model="hotelOrderInfo.keyword" placeholder="搜索酒店名/地名/关键词" />
                      </van-col>
                    </van-row>
                  </van-col>
                </van-row>
              </template>
            </van-cell>
            <!-- 车型选择cell -->
            <!-- 搜索按钮cell -->
            <van-cell>
              <template slot="title">
                <van-row type="flex" justify="center" align="center" style="height: 60px">
                  <van-button
                    style="border-radius: 8px;font-size: 16px"
                    block
                    @click="searchTrain"
                    color="linear-gradient(to right, #4bb0ff, #2c50d4)"
                  >搜 索</van-button>
                </van-row>
              </template>
            </van-cell>
            <!-- 搜索按钮cell -->
          </van-row>
        </van-row>
      </van-row>
    </van-row>

    <!--日期选择-->
    <van-calendar
      v-model="show_calendar"
      color="#198BDA"
      confirm-text="确定"
      type="range"
      :show-confirm="false"
      @confirm="calendarConfirm"
    />

    <!-- 遮罩层 -->
    <van-overlay :show="loading" @click="show = false">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" />
      </div>
    </van-overlay>
  </van-row>
</template>

<script>
import { mapGetters } from "vuex";
import util from "../../common/util";
import logic from "../../services/commonLogic";
import constant from "../../services/constant";
export default {
  name: "BuyTrain",
  computed: {
    ...mapGetters(["travelInfo", "hotelOrderInfo"])
  },
  mounted() {
    window.setTitle("选择城市");
    // 如果缓存中没有数据，从差旅项目信息中同步数据。VUEX
    if (this.hotelOrderInfo.date == "") {
      this.$store.dispatch("hotel/setHotelOrderInfo", {
        date: [this.travelInfo.trainDate], // 入住日期
        destination: this.travelInfo.trainToStation, // 目的地
        keyword: "" // 关键词
      });
    }
  },
  data() {
    return {
      loading: false,
      canChange: true, // 是否可以点击交换城市
      show_calendar: false, // 展示日历
    };
  },
  methods: {
    // 选择日期 转到选择日期页面 参数是vuex的actions名称
    chooseCity(vuxFunction) {
      this.loading = true;
      setTimeout(() => {
        this.$router.push({
          path: "/selectCity",
          query: logic.queryPathEncrypt({ function: vuxFunction })
        });
      }, 100);
    },
    // 日历 选择日期
    calendarConfirm(date) {
      this.hotelOrderInfo.date = date;
      this.show_calendar = false;
    },
    // 搜索按钮
    searchTrain() {
      this.$store.dispatch("hotel/setHotelOrderInfo", this.hotelOrderInfo);
      this.$router.push({
        path: "/searchHotel"
      });
    }
  },
};
</script>

<style scoped>
</style>